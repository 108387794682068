/**
 * Created by bfulop on 03/02/15.
 */

var app = (function app() {
    'use strict';
    // var startApp = function () {
    //    app.core.startAll();
    // };
    //
    // return { startApp: startApp }
});




